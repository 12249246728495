import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Context/MyContextProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/ui/common/Header/page.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/LiveMessanger.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/Scrolltop.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"block\",\"variable\":\"--font-manrope\"}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Space_Mono\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"block\",\"variable\":\"--font-mono\"}],\"variableName\":\"spacemono\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Manrope\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"block\",\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Lato\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"700\",\"900\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"block\",\"variable\":\"--font-lato\"}],\"variableName\":\"lato\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/ui/common/Footer/Footer.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/award.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/canada.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/Clutch.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/cmmiCertificatio.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/FooterLogo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/G2.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/goodfirmsPreview.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/google.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/googlePartner.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/india.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/locationMap.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/mail.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/phone.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/tanTheta.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/trust-Pilot.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/uae.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/uk.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Footer/usa.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/whatsapp-logo-fill.png")